import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import Showdown from 'showdown'

class solutionTextImage extends React.Component {
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const linkClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'divlink-we' : 'divlink'
    const converter = new Showdown.Converter()

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
        <MDBContainer>
          <MDBRow className={`about-item align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
            <MDBCol md={this.props.data.feature ? '5' : '7'}>
              <GatsbyImage
                image={this.props.data.image.childImageSharp.gatsbyImageData}
                alt={this.props.data.alttext}
                className="alt-bg-image rounded"
              />
            </MDBCol>
            <MDBCol md={!this.props.data.feature ? '5' : '7'} className="mt-5 mt-md-0">
              {!this.props.data.title.includes('***') && 
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-3 ${textClass}`}>
                  {this.props.data.title}
                </h3>
              }

              {this.props.data.subtitle && 
                <h4 className={`font-alt font-w-400 letter-spacing-1 pb-4 title-xs-medium title-small ${textClass}`}>
                  {this.props.data.subtitle}
                </h4>
              }

              {this.props.data.description && 
                <div
                  className={`font-w-400 text-medium pb-4 richtext ${textClass}`}
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.description), }}
                />
              }

            <ul className="fa-ul px-0 px-md-2">
              {this.props.data.feature.map((features, index) => (
                <li key={index}>
                  <span className="fa-li">
                    <FontAwesomeIcon
                      icon={['fat', features.icon]}
                      className={`va-m  ${this.props.sectionClass.includes('bg-blue-gradient') ? 'ico-color-white' : 'ico-color-blue' }`}
                      size="2x"
                    />
                  </span>
                  <div
                  className={`font-w-400 text-medium pl-4 richtext ${textClass} ${linkClass}`}
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(features.title), }}
                />

                <div
                  className={`font-w-400 text-medium pl-4 richtext ${textClass} ${linkClass}`}
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(features.description), }}
                />
                </li>
              ))}
            </ul>

            {(this.props.data.link || this.props.data.document) && 
              <>
                {this.props.data.link ? (
                  <Link to={this.props.data.link} className="nav-link btn btn-mdb-color btn-sm-block mt-3 ml-0 ml-md-5" >
                    <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                    {this.props.data.linktext}
                  </Link>
                ) : (
                  <a href={this.props.data.document.publicURL} className="nav-link btn btn-mdb-color btn-sm-block mt-3" target="_blank" >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    {this.props.data.linktext}
                  </a>
                )}
              </>
            }
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionTextImage