import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import Link from './hybridLink.js'

class solutionCardsDx extends React.Component {
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } >
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <h2 className={`my-5 font-alt font-w-700 letter-spacing-1 title-xs-medium title-large ${textClass}`} >{this.props.data.title}</h2>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-3">
            {this.props.data.feature.map((features, index) => (
              <MDBCol lg="12" md="12" className="d-flex" key={index}>
                <div className="card flex-fill no-box-shadow card-body-transparent">
                  <div className="card-body mb-4 mb-md-0">
                    <Link to={features.link}>
                      <div className={`row d-flex no-bg-md ${ (index + 1) % 3 === 0 ? 'dark-blue-background' : (index + 1) % 2 === 0 ? 'blue-background' : 'teal-background' }`} testimonial key={index} style={{ borderRadius: '50px' }} >
                        <div className="col-md-2 p-0">
                          {features.image && (
                            <GatsbyImage image={ features.image.childImageSharp.gatsbyImageData } alt={features.alttext} className="mr-4" />
                          )}
                        </div>
                        <div className="my-auto col-md-8 ml-md-n3 ml-lg-n5 ml-0">
                          <h3 className="font-alt font-w-700 text-white letter-spacing-1 title-medium title-xs-medium my-4 my-md-3 my-lg-0">
                            {features.title}
                          </h3>
                          <p className="font-w-400 text-white text-medium mb-0">
                            {features.description}
                          </p>
                        </div>
                        <div className="my-auto col-md-2 p-0 text-right d-none d-sm-block">
                          <GatsbyImage image={ this.props.arrow.childImageSharp.gatsbyImageData } alt="white digital experience arrow" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionCardsDx