import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Showdown from 'showdown'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

class SectionYouTube extends React.Component {
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const converter = new Showdown.Converter()    
    const Heading = this.props.data.htag ? this.props.data.htag : 'h3'
    const headingClass = this.props.data.htag === 'h3' ? 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mb-2' : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3'
    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
        <MDBContainer>
          {this.props.sectionTitle &&
            <MDBRow>
              <h2
                className={`font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium pb-3 ${textClass}`}>
                {this.props.sectionTitle}
              </h2>
            </MDBRow>
          }
          <MDBRow className={`about-item align-items-top ${ this.props.data.placement === 'right' ? 'flex-row-reverse' : '' }`} >
            {(this.props.data.link != null && this.props.data.link != "") && (
              <MDBCol lg="6">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item rounded border"
                    src={`${this.props.data.link}?rel=0&modestbranding=1`}
                    allowfullscreen="allowfullscreen"
                  ></iframe>
                </div>
              </MDBCol>
            )}

              <MDBCol lg={(this.props.data.link != null && this.props.data.link.length >= 1) ? '6' : '12'} className="mt-4 mt-lg-0 mb-4 mb-lg-0" >
                <Heading
                  className={`${headingClass} ${textClass}`}>
                  {this.props.data.title}
                </Heading>
                {this.props.data.subtitle != null && (
                  <div className={`font-alt font-w-400 letter-spacing-1 title-medium ${textClass}`}>
                    {this.props.data.subtitle}
                  </div>
                )}
                <div
                  className={`font-w-400 text-medium richtext divlink pt-4 ${textClass}`}
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.intro) }}
                />
              </MDBCol>

              {this.props.data.description && this.props.data.description != '' && (
                <MDBCol lg="12">
                  <div
                    className="mt-md-4 mb-3 font-w-400 text-medium richtextYouTube divlink pt-4"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.description) }}
                  />
                </MDBCol>
              )}
              
              <MDBCol lg="12">
                {this.props.data.document &&          
                    <a
                      href={this.props.data.document.publicURL}
                      className="nav-link btn btn-mdb-color btn-sm-block mt-4"
                      target="_blank"
                      rel="noopener"
                      aria-label={this.props.data.title}
                    >
                      {this.props.data.icon == 'right' ? (
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      ) : (
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      )}
                      {this.props.data.linktext}
                    </a>            
                }
              </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default SectionYouTube
