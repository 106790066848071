import React from 'react'
import { Link } from 'gatsby'

export default function ConditionalLink({ children, ...props }) {  
    const renameProp = (
        oldProp,
        newProp,
    { [oldProp]: old, ...others }
    ) => ({
        [newProp]: old,
        ...others
    })

  return !props.to.startsWith("http") ? <Link {...props}>{children}</Link> : <a {...renameProp('to', 'href', props)} target="_blank">{children}</a>
}