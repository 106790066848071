import React from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBTestimonial } from 'mdbreact'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'

class CarouselQuote extends React.Component {
  render() {
    return (
    <section className="blockback">
      <MDBContainer>
        <MDBCarousel
          activeItem={1}
          length={this.props.data.length}
          showControls={false}
          showIndicators={(this.props.data.length > 1)}
          interval={9000}
          className="carousel-height"
          style={{ minHeight: '550px', maxHeight: '650px' }}
        >
          <MDBCarouselInner>
            {this.props.data.map((quote, index) => (
            <MDBCarouselItem itemId={index + 1} key={index}>
              <MDBTestimonial>
                <div className="blockquote-wrapper">
                  <div className="blockquote">
                    {quote.title &&
                      <h2>{quote.title}</h2>
                    }

                  <div className="strapline">{quote.strapline}</div>
                  <div className="author">{quote.author}</div>

                  {(quote.link || quote.document) && (
                  <>
                    {quote.link ? (
                      <>
                      {quote.link.substring(0, 4) != 'http'? (
                        <Link
                          to={quote.link}
                          className="nav-link btn btn-cta-color btn-sm-block mt-3"
                        >
                          <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                          {quote.linktext}
                        </Link>
                      ) : (
                        <a href={quote.link} className="nav-link btn btn-cta-color btn-sm-block mt-3" target="_blank"> 
                          <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                          {quote.linktext}
                        </a>
                      )}
                      </>
                    ) : (
                      <a
                        href={quote.document.publicURL}
                        className="nav-link btn btn-cta-color btn-sm-block mt-3"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                        {quote.linktext}
                      </a>
                    )}
                  </>
                  )}
                  </div>
                </div>
              </MDBTestimonial>
            </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    </section>
    )
  }
}

export default CarouselQuote