import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBContainer, MDBRow, MDBCol, MDBAvatar, MDBCard, MDBCardUp, MDBCardBody, MDBCardText } from 'mdbreact'
import Showdown from 'showdown'

class solutionCards extends React.Component {
  
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const justify = this.props.sectionCenter ? 'justify-content-center' : 'justify-content-left'
    const textJustify = this.props.sectionCenter ? 'text-center' : 'text-left' 
    const converter = new Showdown.Converter()

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" >
              <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large mb-5 ${textClass} ${textJustify}`}>{this.props.data.title}</h3>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-5 justify-content-center">
            {this.props.data.feature.map((features, index) => (
              <MDBCol lg="4" md="6" className="pb-card d-flex mb-5" key={index}>
                <MDBCard className={`flex-fill ${ index % 2 === 0 ? 'blue-background' : 'teal-background' }`} testimonial key={index}>
                  <MDBCardUp className="pb-5" />
                    <MDBAvatar className="mx-auto white" style={{ marginTop: "-158px"}}>
                      {features.image && 
                        <GatsbyImage
                          image={features.image.childImageSharp.gatsbyImageData}
                          alt={features.alttext}
                        />
                      }
                    </MDBAvatar>
                    <MDBCardBody className="mt-3 rounded-bottom">
                      <div style={{ minHeight: '80px', maxHeight: '80px' }}>
                        <h4 className="font-alt font-w-700 text-white mt-2 letter-spacing-1 title-medium title-xs-medium">
                          {features.title}
                        </h4>
                      </div>
                      <MDBCardText tag="div">
                        <div
                          className={`font-w-400 text-white text-medium mt-4 pb-3 ${textClass}`}
                          style={{ whiteSpace: 'pre-wrap' }}
                          dangerouslySetInnerHTML={{ __html: converter.makeHtml(features.description), }}
                        />
                      </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionCards