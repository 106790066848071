import React from 'react'
import Showdown from 'showdown'
import { MDBContainer, MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader} from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons'

class faq extends React.Component {
  state = {
    collapseID: null,
  }

    toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    })
    )
  }

  render() {
    const { collapseID } = this.state
    const converter = new Showdown.Converter()

    return (
      <MDBContainer className="md-accordion px-0">
        {this.props.items.map((item, index) => {
          return (
            <MDBCard className="ml-0" key={index}>
              <MDBCollapseHeader tag="div" tagClassName="d-flex justify-content-between mt-3" onClick={() => this.toggleCollapse(`collapse${index}`)}>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mb-3" style={{ color: '#2c4c71' }}>
                  {item.question}
                </h3>

                <FontAwesomeIcon icon={collapseID === `collapse${index}` ? faAngleUp : faAngleDown} className="fa-xl mt-1 ico-color" />
                
              </MDBCollapseHeader>

              <MDBCollapse id={`collapse${index}`} isOpen={collapseID}>
                <MDBCardBody className="pt-0 mb-3 pl-4">
                  <div className="text-medium text-black mb-0 richtext divlink faq" dangerouslySetInnerHTML={{ __html: converter.makeHtml(item.answer) }} />
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          )
        })}
      </MDBContainer>
    )
  }
}

export default faq
