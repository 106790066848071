import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'

class solutionBulletsVertical extends React.Component {
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'   
    return (
      <section className="bg-light-blue" key={this.props.key}>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" className="pr-5">
            {this.props.data.title != null && 
              <h2 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5 ${textClass}`}>
                {this.props.data.title}
              </h2>
            }
            {this.props.data.description != null && 
              <p className={`text-medium mt-3 mb-5 ${textClass}`}>
                {this.props.data.description}
              </p>
            }
            {this.props.data.linktext != null && 
              <p className={`text-medium mt-3 ${textClass}`}>
                {this.props.data.linktext}
              </p>
            }
           {this.props.data.link != null && 
              <p className={`text-medium mt-3 ${textClass}`}>
                {this.props.data.link}
              </p>
           }
            </MDBCol>

            <ul className="fa-ul px-3">
              {this.props.data.feature.map((features, index) => (
                <li className="pb-3" key={index}>
                  <span className="fa-li">
                    <FontAwesomeIcon
                      icon={['fat', features.icon]}
                      size="2x va-m ico-color"
                    />
                  </span>
                  {features.title != null && 
                  <p className={`text-medium font-w-700 ml-4 ${textClass}`}>
                    {features.title}
                  </p>
                  }
                  {features.description != null &&
                  <p className={`text-medium ${textClass}`}>
                    {features.description}
                  </p>
                  }
                </li>
              ))}
            </ul>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionBulletsVertical
