import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation} from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import Hero from '../components/heroSubpage'
import Link from 'gatsby-link'
import Cta from '../components/cta'
import Faqs from '../components/faqs'
import Card from '../components/card'
import SolutionBulletsVertical from '../components/solutionBulletsVertical'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionText from '../components/solutionText'
import SolutionFeatures from '../components/solutionFeatures'
import SolutionCards from '../components/solutionCards'
import SolutionCardsDx from '../components/solutionCardsDx'
import SolutionTextImage from '../components/solutionTextImage'
import SolutionBulletsImage from '../components/solutionBulletsImage'
import SolutionTextImageBelow from '../components/solutionTextImageBelow'
import CarouselQuote from '../components/carouselQuote'
import Related from '../components/related'
import SectionYouTube from '../components/sectionYouTube'
import SolutionCardSet from '../components/solutionCardSet'
import CardIcons from '../components/cardIcons.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const SolutionsTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const dxarrow = data.dxarrow 
  const converter = new Showdown.Converter()  

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src }

        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg={ post.frontmatter.logo && post.frontmatter.logo ? '10' : '12' }>

              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />

            {post.html && (
              <div
                className="richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            )}
            </MDBCol>

            {post.frontmatter.logo && (
              <MDBCol lg="2" md="12">
                <GatsbyImage 
                  image={post.frontmatter.logo.childImageSharp.gatsbyImageData} 
                  alt="company logo" 
                  className="mt-md-3 mt-lg-2" />
              </MDBCol>
            )} 


            {(post.frontmatter.headline && post.frontmatter.headline.length >= 1) && 
                <MDBCol lg="4" md="12" className="pl-lg-5">
                  {post.frontmatter.headline.map((headlines, index) => (
                    <div
                      className="headline-tile mt-lg-3 item-headline"
                      key={index}
                    >
                      <div className="headline-icon">
                        <FontAwesomeIcon icon={['fat', headlines.icon]} size="2x" className="fa-fw" />
                      </div>
                      <div className="headline-content">
                        <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium">
                          {headlines.title}
                        </h3>
                        <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                          {headlines.resource ? (
                            <a
                              href={headlines.resource.publicURL}
                              className="effect-noline"
                              target="_blank"
                              rel="noopener"
                            >
                              {headlines.linktext}
                              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                            </a>
                          ) : headlines.link.charAt(0) != '/' ? (
                            <a
                              href={headlines.link}
                              className="effect-noline"
                              target="_blank"
                              rel="noopener"
                            >
                              {headlines.linktext}
                              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                            </a>
                          ) : (
                            <Link to={headlines.link} className="effect-noline">
                              {headlines.linktext}
                              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </MDBCol>
              }
          </MDBRow>
        </MDBContainer>
      </section>

    {post.frontmatter.section && 
    <>
      {post.frontmatter.section.map((sections, index) => (
        <Fragment key={index}>
          {sections.type === 'bullets vertical' && (
            <SolutionBulletsVertical data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'text' && (
            <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'features' && (
            <SolutionFeatures data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'cards' && (
            <SolutionCards data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'bullets horizontal' && (
            <SolutionBulletsHorizontal data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'text with image below' && (
            <SolutionTextImageBelow data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'text and image' && (
            <SolutionTextImage data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}
          {sections.type === 'bullets and image' && (
            <SolutionBulletsImage data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}
          {sections.type === 'dx cards' && (
            <SolutionCardsDx data={sections} arrow={dxarrow} sectionClass={sections.class} />
          )}
          {sections.type === 'video' && (
            <SectionYouTube data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}          
          {sections.type === 'card set' && (
            <SolutionCardSet data={sections} sectionClass={sections.class} height="11rem" />
          )}
        </Fragment>
      ))}
      </>
    }

      {post.frontmatter.bragroll && (
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                {post.frontmatter.bragroll.title}
              </h2>
              {post.frontmatter.bragroll.subtitle && 
                <p className="text-medium">{post.frontmatter.bragroll.subtitle}</p>
              }
              {post.frontmatter.bragroll.card && 
                <MDBRow className="pt-5">
                  {post.frontmatter.bragroll.card.map((cards, index) => (
                      <CardIcons
                        key={index}
                        collg={location.pathname == "/solutions/session-management/" ? "6" : "4"}
                        colmd="6"
                        title={cards.title}
                        subtitle={cards.subtitle}
                        image={cards.image.childImageSharp.gatsbyImageData}
                        alt={cards.alttext}
                      />
                    ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      {post.frontmatter.quote && (
        <CarouselQuote data={post.frontmatter.quote} />
      )}

        {(post.frontmatter.related && post.frontmatter.related.length > 0) &&
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  Explore more
                </h2>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              {post.frontmatter.related.map((relate, index) => (
                <Fragment key={index}>
                  {(relate.document || relate.link) && (
                    <Related
                      key={index}
                      collg={((post.frontmatter.related.length % 4 === 0) && (!post.frontmatter.related.length % 3 === 0)) ? "6" : "4"}
                      colmd="6"
                      height="9.5rem"
                      type={relate.type}
                      title={relate.title}
                      description={relate.description}
                      linktext={relate.linktext}
                      link={
                        relate.document
                          ? relate.document.publicURL
                          : relate.link
                      }
                    />
                  )}
                </Fragment>
              ))}
            </MDBRow>
          </MDBContainer>
        </section>
      }

      {post.frontmatter.helpfulresources && 
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">
                {post.frontmatter.helpfulresources.title}
                <span id="helpful-resources" className="anchor">
                        &nbsp;
                </span> 
              </h2>
              
              {post.frontmatter.helpfulresources.subtitle && (
               <p className="text-medium">
                 {post.frontmatter.helpfulresources.subtitle}
               </p>
              )}

              {post.frontmatter.helpfulresources.helpfulres && 
                <MDBRow className="pt-5">
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="10rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={ helpfulres.image.childImageSharp.gatsbyImageData }
                      alttext={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      titleclass="title-small"
                      descriptionClass="text-card-small py-2"
                    />
                  ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      }

      {(post.frontmatter.faq && post.frontmatter.faq.length >= 1) && 
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="pb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                  Frequently asked questions (FAQ)
                </h2>
              </MDBCol>
            </MDBRow>

            {post.frontmatter.faq && <Faqs items={post.frontmatter.faq} />}
          </MDBContainer>
        </section>
      }

      {post.frontmatter.cta && 
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      }
    </Layout>
  )
}
export default SolutionsTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "solutions" } }
    ) {
      fields {
        slug
      }
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        date(formatString: "MMMM DD, YYYY")
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        logo {
          childImageSharp {
            gatsbyImageData(width: 154, quality: 90)
          }
        }        
        headline {
          title
          icon
          description
          linktext
          link
          resource {
            publicURL
          }
        }
        quote {
          strapline
          author
          link
          linktext
          document {
            publicURL
          }
        }
        section {
          title
          htag
          subtitle
          intro
          description
          class
          center
          type
          link
          linktext
          placement
          document {
            publicURL
          }
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            subtitle
            icon
            description
            link
            linktext
            colour
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        bragroll {
          title
          subtitle
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        related {
          type
          title
          description
          linktext
          link
          document {
            publicURL
          }
        }
        faq {
          question
          answer
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    dxarrow: file(name: { eq: "dot-right-arrow" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  } 
`